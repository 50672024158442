const setAuthToken = (axiosInstance) => {
  const token = `Bearer ${localStorage.getItem("token")}`;
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = token;
  } else {
    axiosInstance.defaults.headers.common["Authorization"] = "";
  }
};

export default setAuthToken;
