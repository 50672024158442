import { UploadOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Switch,
  Upload,
  message,
} from "antd";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storageConstants } from "../../common/constants";
import { addBlog, getBlog, updateBlog } from "./Blogs.service";
import "./blog.module.css";
const config = {
  rules: [{ type: "object", required: true, message: "Please select Date" }],
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const BlogForm = () => {
  const editorRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useState("add");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    form.setFieldsValue({
      content: "",
      title: "",
      description: "",
      createDate: "",
      category: storageConstants.CATEGORY_OPTIONS[0].value,
      pagePath: "",
      image: null,
    });

    blogHandler();
  }, []);

  const blogHandler = async () => {
    if (state && (state.type === "view" || state.type === "edit")) {
      const viewData = await getBlog(state.id);
      form.setFieldsValue({
        content: viewData.data.description,
        title: viewData.data.title,
        description: viewData.data.metadescription,
        createDate: moment(viewData.data.date),
        category: viewData.data.metakeywords,
        pagePath: viewData.data.url,
        image: viewData.data.imgpath,
      });
      setFileList([
        {
          uid: "1",
          name: "Image",
          status: "done",
          url: viewData.data.imgpath,
        },
      ]);
      setStatus(viewData.data.status);
    }
    setMode(state.type || mode);
  };

  const handleSubmit = () => {
    const editorData = editorRef.current.getContent();
    const formData = new FormData();
    formData.append("title", form.getFieldValue("title"));
    formData.append("description", form.getFieldValue("description"));
    formData.append("category", form.getFieldValue("category"));
    formData.append(
      "date",
      moment(form.getFieldValue("createDate")).format("MM-DD-YYYY")
    );
    formData.append("pagePath", form.getFieldValue("pagePath"));
    formData.append("file", form.getFieldValue("image"));
    formData.append("content", editorData);
    formData.append("status", status);

    if (mode === "add") {
      addBlogHandler(formData);
    } else if (mode === "edit") {
      updateBlogHandler(formData);
    }
  };

  const addBlogHandler = (formData) => {
    addBlog(formData)
      .then((data) => {
        if (data && data.success) {
          form.resetFields();
          message.success(data.message ? data.message : "successfully added");
          navigate(storageConstants.PAGES.ROUTE.BLOGS);
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        message.error(error.message ? error.message : "failed");
      });
  };

  const updateBlogHandler = (formData) => {
    updateBlog(state.id, formData)
      .then((data) => {
        if (data && data.success) {
          form.resetFields();
          message.success(data.message ? data.message : "successfully updated");
          navigate(storageConstants.PAGES.ROUTE.BLOGS);
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        message.error(error.message ? error.message : "failed");
      });
  };

  const cancel = () => {
    form.resetFields();
    navigate(storageConstants.PAGES.ROUTE.BLOGS);
  };

  const handleCategoryChange = (e) => {
    form.setFieldValue("category", e);
  };

  const getTitle = () => {
    if (mode === "view") return "View Blog";
    else if (mode === "add") return "Add Blog";
    else return "Edit Blog";
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = (e) => {
    setFileList([]);
    setFileList(e.fileList);
  };

  const customRequest = (e) => {
    form.setFieldValue("image", e.file);
    e.onSuccess([], e.file);
  };
  return (
    <>
      <Layout.Content>
        <Card
          title={getTitle()}
          extra={
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(e) => setStatus(e)}
              checked={status}
              disabled={mode === "view"}
            />
          }
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            disabled={mode === "view"}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "title is required!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Page Path"
                  name="pagePath"
                  rules={[
                    {
                      required: true,
                      message: "page path is required!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Page Path" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Category"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "category is required!",
                    },
                  ]}
                >
                  <Select
                    onChange={handleCategoryChange}
                    options={storageConstants.CATEGORY_OPTIONS}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "description is required!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Description" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="createDate"
                  label="Create Date"
                  {...config}
                  rules={[
                    {
                      required: true,
                      message: "created date is required!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="DD-MM-YYYY"
                    style={{ minWidth: "100%", width: "100%" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="image"
                  label="Image"
                  rules={[
                    {
                      required: true,
                      message: "image is required!",
                    },
                  ]}
                >
                  {mode === "view" ? (
                    <Image
                      height={50}
                      width={50}
                      src={form.getFieldValue("image")}
                      fallback={storageConstants.FALLBACK}
                    />
                  ) : (
                    <>
                      <Upload
                        accept="image/*"
                        maxCount={1}
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={(e) => handleChange(e)}
                        customRequest={(e) => customRequest(e)}
                        onRemove={() => {
                          form.setFieldValue("image", null);
                        }}
                      >
                        {fileList && fileList.length ? null : (
                          <Button
                            icon={
                              <UploadOutlined
                                onClick={(e) => e.preventDefault()}
                              />
                            }
                          >
                            Upload
                          </Button>
                        )}
                      </Upload>
                      <Image
                        width={200}
                        style={{ display: "none" }}
                        src={previewImage}
                        fallback={storageConstants.FALLBACK}
                        preview={{
                          visible: previewOpen,
                          src: previewImage,
                          onVisibleChange: (value) => {
                            setPreviewOpen(value);
                          },
                        }}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Editor
                disabled={mode === "view"}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={form.getFieldValue("content")}
                init={{
                  height: 400,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "table | image | code |" +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Form.Item>
            {mode !== "view" ? (
              <Space style={{ float: "right" }}>
                <Button type="default" onClick={cancel}>
                  Cancel
                </Button>

                <Button type="primary" htmlType="submit">
                  {mode === "edit" ? "Update" : "Create"}
                </Button>
              </Space>
            ) : (
              <></>
            )}
          </Form>
        </Card>
      </Layout.Content>
    </>
  );
};

export default BlogForm;
