import {
	ContainerOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { storageConstants } from "../../../common/constants";
const { Sider } = Layout;

function getItem(show, label, key, icon, children, type) {
  if (show) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  } else return;
}

const SideMenu = (props) => {
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    const selectedMenuItem = event.domEvent.currentTarget; // Get the selected menu item DOM element
    const selectedMenuLabel = selectedMenuItem.textContent; // Get the label (text) of the selected menu item
    props.updateAuthUser({
      path: event.key,
      currentMenuTitle: selectedMenuLabel,
    });
    navigate(event.key);
  };

  return (
    <Sider trigger={null} collapsible collapsed={props.collapsed}>
      <div className="logo">
        <h3
          style={{
            color: "white",
            textAlign: "center",
          }}
        >
          {storageConstants.PAGES.TITLE.INNOBAYT}
        </h3>
      </div>
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[
          props.user?.path || storageConstants.PAGES.ROUTE.BLOGS,
        ]}
        onClick={handleMenuClick}
        items={[
          getItem(
            true,
            storageConstants.PAGES.MENU.BLOGS,
            storageConstants.PAGES.ROUTE.BLOGS,
            <ContainerOutlined />
          ),

          getItem(
            true,
            storageConstants.PAGES.MENU.ACCOUNTS,
            storageConstants.PAGES.ROUTE.ACCOUNTS,
            <UsergroupAddOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.PROFILE,
            storageConstants.PAGES.ROUTE.PROFILE,
            <UserOutlined />
          ),
        ]}
      />
    </Sider>
  );
};

export default SideMenu;
