import React, { lazy } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { storageConstants } from './common/constants';
import BlogForm from './components/Blogs/BlogForm';


const Login = lazy(() => import('./components/Login/Login'));
const Blogs = lazy(() => import('./components/Blogs/Blogs'));
const Profile = lazy(() => import('./components/Profile/Profile'));


const ViewAccount = lazy(() =>
	import('./components/Accounts/ViewAccount/ViewAccount')
);
const NotFound = lazy(() => import('./components/common/error/NotFoundError'));
const InternalServerError = lazy(() =>
	import('./components/common/error/InternalServerError')
);


const authenticatedRoutes = (props) => {
	return [
		{
			path: storageConstants.PAGES.ROUTE.BLOGS,
			element: <Blogs {...props} />,
		},
		
		{
			path: storageConstants.PAGES.ROUTE.PROFILE,
			element: <Profile {...props} />,
		},
		{
			path: storageConstants.PAGES.ROUTE.ACCOUNTS,
			element: <ViewAccount {...props} />,
		},
		{
			path: storageConstants.PAGES.ROUTE.BLOG_FORM,
			element: <BlogForm {...props} />,
		},
		{
			path: '500',
			element: <InternalServerError {...props} />,
		},
		{
			path: '*',
			element: <NotFound {...props} />,
		}
	];
};

const unauthenticatedRoutes = (props) => {
	return [
		{
			path: storageConstants.PAGES.ROUTE.LOGIN,
			element: <Login {...props} />,
		},
	];
};

const Routes = (props) => {
	const navigate = useNavigate();
	const isAuthenticated = props.user?.isAuthenticated || localStorage.getItem('token') ? true : false;
	
	const routingConfig = useRoutes(
		isAuthenticated ? authenticatedRoutes(props) : unauthenticatedRoutes(props)

	);

	React.useEffect(() => {
		if (!isAuthenticated) {
			navigate(storageConstants.PAGES.ROUTE.LOGIN);
		}
	}, [navigate]);

	return routingConfig;
};

export default Routes;
