import actions from "./actions";

const setAuthUser = (user) => {
  return (dispatch) => {
    return dispatch(actions.setAuthUser(user));
  };
};

const updateAuthUser = (data) => {
  return (dispatch) => {
    return dispatch(actions.updateAuthProps(data));
  };
};

const resetAuthUser = (data) => {
  return (dispatch) => {
    return dispatch(actions.resetAuthProps(data));
  };
};

export default {
  setAuthUser,
  updateAuthUser,
  resetAuthUser,
};
