import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { storageConstants } from "../../../common/constants";
import { persistor } from "../../../redux/Store/Store";
const Header = (props) => {
  const navigate = useNavigate();

  const logout = () => {
    persistor.flush().then(() => {
      props.setAuthUser({ isAuthenticated: false });
      props.resetAuthUser();
      localStorage.clear();
      sessionStorage.clear();
      return navigate(storageConstants.PAGES.ROUTE.LOGIN);
    });
  };
  const items = [
    {
      key: "profile",
      label: (
        <Button
          type="link"
          icon={<UserOutlined />}
          onClick={() => navigate(storageConstants.PAGES.ROUTE.PROFILE)}
        >
          {storageConstants.PAGES.PROFILE.TITLE}
        </Button>
      ),
    },
    {
      key: "logout",
      label: (
        <Button type="link" icon={<LogoutOutlined />} onClick={logout} danger>
          {storageConstants.PAGES.LOGOUT.TITLE}
        </Button>
      ),
    },
  ];

  return (
    <header
      style={{
        padding: 0,
      }}
    >
      <Button
        type="text"
        icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => props.setCollapsed(!props.collapsed)}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      />

      <Space style={{ float: "right", padding: "20px" }}>
        <Dropdown
          menu={{
            items,
          }}
        >
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      </Space>
    </header>
  );
};

export default Header;
