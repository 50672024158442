import { getApi, deleteApi, postFormData, putFormData } from "../../services/api";

export const getBlogsList = () =>{
  return getApi(`/blog`);
}

export const deleteBlog = (blogId) =>
deleteApi(`/blog/${blogId}`);

export const getBlog = (blogId) =>
getApi(`/blog/${blogId}`);

export const addBlog = (data) =>
postFormData(`/blog`,data);

export const updateBlog = (blogId,data) =>
putFormData(`/blog/${blogId}`,data);
