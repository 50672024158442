import { combineReducers } from "redux";
import types from "./types";

let dataState = {
  user: null,
  path: "/",
  currentMenuTitle: "",
  breadCrumbPath: null,
};

const main = (state = dataState, action) => {
  switch (action.type) {
    case types.AUTH_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          details: action.payload.user,
        },
      };
    }
    case `UPDATE_${types.AUTH_USER}`: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case `RESET_${types.AUTH_USER}`: {
      return {
        ...state,
        user: {},
      };
    }
    default:
      return state;
  }
};

const reducers = combineReducers({
  main,
});

export default reducers;
